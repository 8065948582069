import axios from 'axios';

export default {
  namespaced: true,

  state: {
    compositeCustomersList: [],
    currentCompositeCustomer: {
      id: null,
      name: '',
    },
    emptyCompositeCustomer: {
      id: null,
      name: '',
    },
  },

  getters: {
    compositeCustomersList(state) {
      return state.compositeCustomersList;
    },

    currentCompositeCustomer(state) {
      return state.compositeCustomersList.length
        ? state.compositeCustomersList[0]
        : {
          id: null,
          name: '',
        };
    },

    pagesAccess(state, getters, rootState) {
      return rootState.auth.pages;
    },
  },

  mutations: {
    SET_COMPOSITE_CUSTOMERS_LIST(state, compositeCustomersList) {
      state.compositeCustomersList = compositeCustomersList;
    },

    SET_CURRENT_COMPOSITE_CUSTOMER(state, currentCompositeCustomer) {
      state.currentCompositeCustomer = currentCompositeCustomer;
    },
  },

  actions: {
    updatePagesAccess({ commit }, pagesAccess) {
      commit('auth/SET_PAGES', pagesAccess, { root: true });
    },

    async resetCompositeCustomersList({
      dispatch, commit, state, getters,
    }) {
      const response = await axios
        .get('api/admin/compositeCustomer/list');

      const compositeCustomersList = response.data.map(
        (el) => ({
          id: el.id,
          name: el.name,
          label: el.name,
        }),
      );

      commit('SET_COMPOSITE_CUSTOMERS_LIST', compositeCustomersList);

      let { currentCompositeCustomer } = state;

      if (!state.compositeCustomersList.length) {
        currentCompositeCustomer = JSON.parse(JSON.stringify(state.emptyCompositeCustomer));

        if (getters.pagesAccess?.analytics) {
          dispatch('updatePagesAccess', { ...getters.pagesAccess, analytics: false });
        }
        // eslint-disable-next-line max-len
      } else {
        if (!getters.pagesAccess?.analytics) {
          dispatch('updatePagesAccess', { ...getters.pagesAccess, analytics: true });
        }

        // eslint-disable-next-line max-len
        if (state.currentCompositeCustomer.id === null || !state.compositeCustomersList.some((el) => el.id === state.currentCompositeCustomer.id)) {
          // eslint-disable-next-line prefer-destructuring
          currentCompositeCustomer = state.compositeCustomersList[0];
        }
      }

      commit('SET_CURRENT_COMPOSITE_CUSTOMER', currentCompositeCustomer);
    },

    setCompositeCustomersList({ commit }, compositeCustomersList) {
      commit('SET_COMPOSITE_CUSTOMERS_LIST', compositeCustomersList);
    },

    setCurrentCompositeCustomer({ commit }, currentCompositeCustomer) {
      commit('SET_CURRENT_COMPOSITE_CUSTOMER', currentCompositeCustomer);
    },
  },
};
