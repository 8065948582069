import RequestHelpers from './RequestHelpers';
import Constants from './Constants';
import Websocket from './Websocket';
import NumberManagement from './NumberManagement';
import ArrayManagement from './ArrayManagement';

export default {
  install: (Vue, options) => {
    const helpersRequest = new RequestHelpers(options);
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$requestHelpers = helpersRequest;
    // eslint-disable-next-line no-param-reassign
    Vue.$requestHelpers = helpersRequest;

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$constants = Constants;
    // eslint-disable-next-line no-param-reassign
    Vue.$constants = Constants;

    const websocket = new Websocket();
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$websocket = websocket;
    // eslint-disable-next-line no-param-reassign
    Vue.$websocket = websocket;

    const numberManagement = new NumberManagement();
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$numberManagement = numberManagement;
    // eslint-disable-next-line no-param-reassign
    Vue.$numberManagement = numberManagement;

    const arrayManagement = new ArrayManagement();
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$arrayManagement = arrayManagement;
    // eslint-disable-next-line no-param-reassign
    Vue.$arrayManagement = arrayManagement;
  },
};
