import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    lists: {},
    listItem: {
      list: [],
      isLoading: false,
    },
  },

  getters: {
    listItem: (state) => (type) => {
      if (state.lists[type] === undefined) {
        state.lists[type] = JSON.parse(JSON.stringify(state.listItem));
      }

      return state.lists[type];
    },
  },

  mutations: {
    SET_LIST_IS_LOADING(state, { type, value }) {
      state.lists[type].isLoading = value;
    },

    SET_LIST_ITEM_DATA(state, { type, data }) {
      state.lists[type].list = data;
    },
  },

  actions: {
    // eslint-disable-next-line object-curly-newline
    async forceUpdateList({ commit }, { type, path, requestData = {}, withOverlay = false, method = 'get' }) {
      commit('SET_LIST_IS_LOADING', { type, value: true });

      let requestFunction = null;

      switch (method) {
        case 'get':
          requestFunction = Vue.prototype.$requestHelpers.get(
            `api/admin/${path}`,
            {
              withOverlay,
            },
          );
          break;
        case 'post':
          requestFunction = Vue.prototype.$requestHelpers.post(
            `api/admin/${path}`,
            requestData,
            {
              withOverlay,
            },
          );
          break;
        default:
          throw new Error('wrong http method');
      }

      await requestFunction
        .then((response) => {
          commit('SET_LIST_ITEM_DATA', { type, data: response.data });
        }).finally(() => {
          commit('SET_LIST_IS_LOADING', { type, value: false });
        });
    },

    // eslint-disable-next-line object-curly-newline
    async updateList({ getters, dispatch }, { type, path, requestData = {}, withOverlay = false, method = 'get' }) {
      if (getters.listItem(type).list.length > 0) return;

      // eslint-disable-next-line object-curly-newline
      await dispatch('forceUpdateList', { type, path, requestData, withOverlay, method });
    },

    setStaticList({ commit }, { type, list }) {
      commit('SET_LIST_ITEM_DATA', { type, data: list });
    },
  },
};
