<template>
  <v-overlay :value="show" z-index="210">
    <v-progress-circular
      size="64"
      indeterminate
    >
      {{ currentMessage }}
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'StandBy',
  props: {
    show: {
      type: [Boolean, Number],
      default: 0,
    },
  },
  data: () => ({
    currentMessage: '',
    stepNumber: 0,
    loopTimer: null,
  }),
  methods: {
    messageLoop() {
      this.stepNumber += 1;
      this.currentMessage = `Step ${this.stepNumber}`;
    },
    clearLoop() {
      clearInterval(this.loopTimer);
      this.stepNumber = 0;
      this.loopTimer = null;
    },
  },
  watch: {
    show(val) {
      if (!!val === false) {
        this.clearLoop();
      } else if (this.loopTimer === null) {
        this.messageLoop();
        this.loopTimer = setInterval(
          () => {
            this.messageLoop();
          },
          500,
        );
      }
    },
  },
};
</script>

<style scoped>

</style>
