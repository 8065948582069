export default {
  namespaced: true,

  state: {
    snack: false,
    color: '',
    text: '',
  },

  getters: {
    snack(state) {
      return state.snack;
    },

    color(state) {
      return state.color;
    },

    text(state) {
      return state.text;
    },
  },

  mutations: {
    SET_SNACK_STATE(state, value) {
      state.snack = typeof value.snack === 'undefined' ? true : value.snack;

      if (state.snack) {
        state.text = value.text;
        state.color = value.color;
      }
    },
  },

  actions: {
    setState({ commit }, value) {
      commit('SET_SNACK_STATE', value);
    },
  },
};
