import Echo from 'laravel-echo';
import Cookies from 'js-cookie';

export default class Websocket {
  initEcho = false;

  constructor() {
    // eslint-disable-next-line global-require
    window.io = require('socket.io-client');
  }

  init() {
    if (!this.initEcho) {
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.VUE_APP_SOCKET_HOST,
        auth: {
          headers: {
            'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
          },
        },
      });

      this.initEcho = true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  Echo() {
    this.init();

    return window.Echo;
  }
}
