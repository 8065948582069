export default {
  namespaced: true,

  state: {
    periodNotations: [
      { text: 'Today', value: 'today' },
      { text: 'Last day', value: 'hour_24' },
      { text: 'Last 7 Days', value: 'day_7' },
      { text: 'Last 30 Days', value: 'day_30' },
      { text: 'This Month', value: 'this-month' },
      { text: 'Last month', value: 'month_1' },
      { text: 'This Year', value: 'this-year' },
      { text: 'Last 6 Months', value: 'month_6' },
      { text: 'Last 12 Months', value: 'month_12' },
      { text: 'Custom interval', value: 'custom' },
    ],

    periodSelected: 'day_7',

    isGlobalPercent: false,

    isShowAlternativeData: false,

    isShowTrueData: false,
  },

  getters: {
    periodNotations(state) {
      return state.periodNotations;
    },

    periodSelected(state) {
      return state.periodSelected;
    },

    isShowAlternativeData(state) {
      return state.isShowAlternativeData;
    },

    isShowTrueData(state) {
      return state.isShowTrueData;
    },

    activeDataset(state) {
      return state.isShowAlternativeData ? 'alternative' : 'main';
    },

    isGlobalPercent: (state) => state.isGlobalPercent,

    intervalNotation(state) {
      const notationItem = state.periodNotations.find((el) => el.value === state.periodSelected);

      if (!notationItem) {
        return '';
      }
      return notationItem.text;
    },
  },

  mutations: {
    SET_PERIOD_SELECTED(state, value) {
      state.periodSelected = value;
    },

    SET_IS_GLOBAL_PERCENT(state, value) {
      state.isGlobalPercent = value;
    },

    SET_IS_SHOW_ALTERNATIVE_DATA(state, value) {
      state.isShowAlternativeData = value;
    },

    SET_IS_SHOW_TRUE_DATA(state, value) {
      state.isShowTrueData = value;
    },
  },

  actions: {
    setPeriodSelected({ commit }, value) {
      commit('SET_PERIOD_SELECTED', value);
    },

    setIsGlobalPercent({ commit }, value) {
      commit('SET_IS_GLOBAL_PERCENT', value);
    },

    setIsShowAlternativeData({ commit }, value) {
      commit('SET_IS_SHOW_ALTERNATIVE_DATA', value);
    },

    setIsShowTrueData({ commit }, value) {
      commit('SET_IS_SHOW_TRUE_DATA', value);
    },
  },
};
