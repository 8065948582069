import axios from 'axios';

export default {
  namespaced: true,

  state: {
    authenticated: false,
    abilities: [],
    pages: null,
    user: null,
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },

    user(state) {
      return state.user;
    },

    userId(state) {
      return state.user?.id;
    },

    abilities(state) {
      return state.abilities;
    },

    pages(state) {
      return state.pages;
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },

    SET_USER(state, value) {
      state.user = value;
    },

    SET_ABILITIES(state, value) {
      state.abilities = value;
    },

    SET_PAGES(state, value) {
      state.pages = value;
    },
  },

  actions: {
    async signIn({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie');

      await axios.post('/login', credentials)
        .then(() => {
          dispatch(
            'snackbar/setState',
            {
              snack: true,
              color: 'green',
              text: 'logging in...',
            },
            { root: true },
          );
        })
        .catch(
          (e) => {
            dispatch(
              'snackbar/setState',
              {
                snack: true,
                color: 'red',
                text: e.response.data.errors,
              },
              { root: true },
            );
          },
        );

      return dispatch('me');
    },

    async signOut({ dispatch }) {
      await axios.post('/logout');

      return dispatch('me');
    },

    me({ commit }) {
      return axios.get('/api/user').then((response) => {
        commit('SET_AUTHENTICATED', true);
        commit('SET_USER', response.data);
        commit('SET_ABILITIES', response.data.abilities);
        commit('SET_PAGES', response.data.pages);
      }).catch(() => {
        commit('SET_AUTHENTICATED', false);
        commit('SET_USER', null);
        commit('SET_ABILITIES', []);
        commit('SET_PAGES', null);
      });
    },
  },
};
