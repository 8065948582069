import Vue from 'vue';
import Constants from '../helpers/Constants';

export default {
  namespaced: true,

  state: {
  },

  getters: {
    menuItems: (state, getters) => [
      {
        text: 'User Analytics',
        icon: 'mdi-google-analytics',
        link: 'analytics',
        access_rule: getters.pages.analytics,
      },
      {
        text: 'Content Statistics',
        icon: 'mdi-map-search',
        link: 'destination_analytics',
        access_rule: getters.pages.statistics,
      },
      {
        text: 'Video Assets Library',
        icon: 'mdi-file-video',
        link: 'tripbits_videos',
        access_rule: Vue.prototype.hasAllAbilities([Constants.ABILITY.VIDEO_ASSETS]),
      },
      {
        text: 'Customer Management',
        icon: 'mdi-book-cog',
        link: 'customerManagement',
        access_rule: Vue.prototype.hasAllAbilities([Constants.ABILITY.CUSTOMER_MANAGEMENT]),
      },
      {
        text: 'Bigquery Tables Management',
        icon: 'mdi-table-multiple',
        link: 'bigqueryManagement',
        access_rule: Vue.prototype.hasAllAbilities([Constants.ABILITY.BIGQUERY_MANAGEMENT]),
      },
      {
        text: 'User Management',
        icon: 'mdi-account-details',
        link: 'userManagement',
        access_rule: Vue.prototype.hasAllAbilities([Constants.ABILITY.USER_MANAGEMENT]),
      },
      {
        text: 'Log Version Management',
        icon: 'mdi-counter',
        link: 'logVersionManagement',
        access_rule: Vue.prototype.hasAllAbilities([Constants.ABILITY.LOG_VERSION_MANAGEMENT]),
      },
      {
        text: 'Dashboard Management',
        icon: 'mdi-view-dashboard',
        link: 'dashboardManagement',
        access_rule: Vue.prototype.hasAllAbilities([Constants.ABILITY.DASHBOARD_MANAGEMENT]),
      },
    ],

    // eslint-disable-next-line max-len
    filteredMenuItems: (state, getters) => getters.menuItems.filter((el) => el.access_rule === true),

    pages: (state, getters, rootState, rootGetters) => rootGetters['auth/pages'],
  },

  mutations: {
  },

  actions: {
  },
};
