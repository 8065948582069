import axios from 'axios';

export default class RequestHelpers {
  store = null

  router = null

  constructor(options) {
    this.store = options.store;
    this.router = options.router;
  }

  get(url, config) {
    return this.request('get', url, {}, config);
  }

  post(url, data, config) {
    return this.request('post', url, data, config);
  }

  put(url, data, config) {
    return this.request('put', url, data, config);
  }

  patch(url, data, config) {
    return this.request('patch', url, data, config);
  }

  delete(url, data, config) {
    return this.request('delete', url, data, config);
  }

  request(method, url, data, config) {
    this.store.dispatch('overlay/showOverlay', true);

    const defaultConfig = {
      method,
      url,
      data,
    };
    // eslint-disable-next-line no-param-reassign
    config = this.mergeObjects(config, defaultConfig);

    return new Promise((resolve, reject) => {
      axios(config).then((response) => {
        // eslint-disable-next-line no-prototype-builtins
        if (response.data?.hasOwnProperty('success') && !response.data.success) {
          this.store.dispatch('snackbar/setState', {
            text: response.data.message ? response.data.message : 'Error',
            color: 'error',
          });
          reject(response);
        } else {
          resolve(response);
        }
      }).catch((error) => {
        switch (error.response?.status) {
          case 401:
          case 419:
            this.store.dispatch('snackbar/setState', {
              text: 'Unauthorized',
              color: 'error',
            });
            window.location.reload();
            break;
          default:
            this.defaultCatch(error);
            reject(error);
        }
      }).finally(() => {
        this.defaultFinally();
      });
    });
  }

  defaultCatch(error) {
    this.store.dispatch('snackbar/setState', {
      text: error.response?.data?.message ? error.response.data.message : 'Error',
      color: 'error',
    });
  }

  defaultFinally() {
    this.store.dispatch('overlay/showOverlay', false);
  }

  mergeObjects(target, source) {
    if (target !== undefined) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(source || {})) {
        if (source[key] instanceof Object) {
          Object.assign(source[key], this.mergeObjects(target[key], source[key]));
        }
      }
    }

    return { ...target || {}, ...source };
  }
}
