export default class ArrayManagement {
  // eslint-disable-next-line class-methods-use-this
  normalizedDataArray(dataArray) {
    if (!dataArray.length) {
      return [];
    }

    const sum = dataArray.reduce(
      (accum, cur) => (cur.label !== '<All>'
        ? accum + cur.value
        : accum),
      0,
    );

    const valuesSum = {};

    if (dataArray[0].values !== undefined) {
      Object.keys(dataArray[0].values).forEach((key) => {
        valuesSum[key] = dataArray.reduce(
          (accum, cur) => (cur.label !== '<All>'
            ? accum + cur.values[key]
            : accum),
          0,
        );
      });
    }

    return dataArray.map(
      (el, index) => {
        const newElement = {
          list_index: index,
          label: el.label
            ? this.clearQuotes(el.label)
            : 'n/a',
          value: el.value,
          // eslint-disable-next-line no-nested-ternary
          ratio: el.label === '<All>' ? 100 : (sum === 0 ? 0 : ((100 * el.value) / sum).toFixed(2)),
        };

        // eslint-disable-next-line no-restricted-syntax
        for (const key in el) {
          if (!['list_index', 'value', 'label', 'ratio', 'values'].includes(key)) {
            newElement[key] = el[key];
          }
        }

        if (el.values !== undefined) {
          newElement.values = {};
          Object.keys(el.values).forEach((key) => {
            newElement.values[key] = {
              value: el.values[key],
              // eslint-disable-next-line no-nested-ternary
              ratio: el.label === '<All>' ? 100 : (valuesSum[key] === 0 ? 0 : ((100 * el.values[key]) / valuesSum[key]).toFixed(2)),
            };
          });
        }

        return newElement;
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  clearQuotes(quotedString) {
    return `${quotedString}`.replace(/^"|"$/gm, '');
  }
}
