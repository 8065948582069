import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VuetifyConfirm from 'vuetify-confirm';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import vuetify from './plugins/vuetify';
import abilities from './plugins/abilities';

import store from './store';

import Default from './layouts/Default.vue';
import router from './router';
import helpers from './helpers';
import normalizedDataArray from './mixins/normalizedDataArray';

import 'tiptap-vuetify/dist/main.css';
import '@mdi/font/css/materialdesignicons.css';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(VueAxios, axios);
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'mdi' });
Vue.use(helpers, { store, router });
Vue.use(abilities, { store });
Vue.mixin(normalizedDataArray);

Vue.config.productionTip = false;

store.dispatch('auth/me').then(() => {
  new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(Default),
  }).$mount('#app');
});
