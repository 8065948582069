export default {
  install(Vue, options) {
    let userAbilities = [];

    options.store.subscribe((mutation, state) => {
      if (mutation.type === 'auth/SET_ABILITIES') {
        userAbilities = state.auth.abilities;
      }
    });

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.hasAnyAbility = (abilitiesArray) => abilitiesArray.some(
      (ability) => userAbilities.includes(ability.trim()),
    );

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.hasAllAbilities = (abilitiesArray) => abilitiesArray.every(
      (ability) => userAbilities.includes(ability.trim()),
    );
  },
};
