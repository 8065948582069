export default class NumberManagement {
  // eslint-disable-next-line class-methods-use-this
  contractInteger(num) {
    if (num === null) {
      return 0;
    }

    if (typeof num !== 'number') {
      return 'n/a';
    }

    const numAbs = Math.abs(num);

    if (numAbs < 9999) {
      return num;
    }

    if (numAbs < 999999) {
      return `${Math.ceil(num / 1000)}K`;
    }

    return `${(num / 1000000).toFixed(2)}M`;
  }

  // eslint-disable-next-line class-methods-use-this
  numberWithComma(number) {
    const validNumber = number || 0;

    return validNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // eslint-disable-next-line class-methods-use-this
  secondsToMinSecFormat(seconds) {
    if (seconds === null) {
      return '0:0';
    }

    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;

    return `${min}:${sec < 10 ? '0' : ''}${sec}`;
  }
}
