<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        app
        v-if="authenticated && !hasAnyAbility([$constants.ABILITY.CUSTOMER]) && $route.path !== '/'"
        expand-on-hover
        mini-variant
    >
      <v-list dense>
          <section-btn
              v-for="(menuItem, i) in menuItems"
              :key="i"
              :to="`/${menuItem.link}`"
              :icon="menuItem.icon"
          >
            {{ menuItem.text }}
          </section-btn>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        app
        color="indigo"
        dark
        :dense="true"
    >
      <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="authenticated && $route.path !== '/'"
      >
        <v-btn v-if="hasAnyAbility([$constants.ABILITY.CUSTOMER])"
               icon
               text
               to="/"
        >
          <v-icon>
            mdi-home
          </v-icon>
        </v-btn>
        <v-icon v-else-if="!drawer">mdi-menu</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <b>Passenger Intelligence</b>Cloud
        (<span :style=" {'color':envModeColor}">
            {{ envModeName }}
            </span>)
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="clearCache"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </template>
          <span>Clear cache</span>
        </v-tooltip>
        <template v-if="!authenticated">
          <v-btn icon>
            <v-icon>mdi-login-variant</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-icon
              v-if="hasAllAbilities([$constants.ABILITY.CUSTOMER])"
              small
              color="#FFD600"
          >
            mdi-airplane
          </v-icon>
          <v-btn text>{{ user.name }}</v-btn>
          <v-btn icon @click="signOut">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <v-layout class="fill-height" fluid no-gutters>
        <sign-in v-if="!authenticated"/>
        <keep-alive v-else>
          <router-view/>
        </keep-alive>
      </v-layout>
    </v-main>
    <v-footer color="indigo" app>
      <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>

    <v-snackbar v-model="snackbar.show" :timeout="5000" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-icon
            v-bind="attrs"
            small
            @click.stop="setSnack({snack: false})"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <stand-by :show="overlay"/>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SectionBtn from '../components/SectionBtn.vue';
import SignIn from '../pages/SignIn.vue';
import StandBy from '../components/StandBy.vue';

export default {
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      snack: 'snackbar/snack',
      snackColor: 'snackbar/color',
      snackText: 'snackbar/text',
      menuItems: 'menu/filteredMenuItems',
    }),
    envModeName() {
      return process.env.VUE_APP_DEPLOYED_SITE_NAME;
    },
    envModeColor() {
      return process.env.VUE_APP_DEPLOYED_SITE_COLOR;
    },
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut',
      setSnack: 'snackbar/setState',
    }),

    async signOut() {
      await this.signOutAction();
    },

    clearCache() {
      this.$requestHelpers
        .get('/api/admin/clear/cache')
        .then(() => {
          window.location.reload();
        });
    },
  },

  components: {
    SignIn,
    SectionBtn,
    StandBy,
  },
  props: {
    source: String,
  },

  data: () => ({
    drawer: null,
    mini: true,
    component: null,

    snackbar: {
      text: '',
      color: '',
      show: false,
    },

    overlay: false,
  }),

  created() {
    // this.redirectToFirstPage();
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/SET_SNACK_STATE') {
        this.snackbar.text = this.snackText;
        this.snackbar.color = this.snackColor;
        this.snackbar.show = this.snack;
      }
      if (mutation.type === 'overlay/SHOW_OVERLAY') {
        this.overlay = state.overlay.show;
      }
    });
    document.title = 'Passenger IntelligenceCloud';
  },
};
</script>
