<template>
    <v-list-item link :to="to">
        <v-list-item-action>
            <v-icon>{{ this.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
                <slot></slot>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
  name: 'SectionBtn',
  props: ['icon', 'to'],
};
</script>
