<template>
    <v-container fill-height="fill-height">
        <v-layout align-center="align-center" justify-center="justify-center">
            <v-flex class="login-form text-xs-center">
                <v-col md="4" offset-md="4">
                    <h4 class="display-2 text-center font-weight-bold">Login</h4>
                    <hr>
                    <br>
                    <v-form ref="form" v-model="valid" :lazy-validation="false">
                        <v-text-field
                            clearable
                            autofocus
                            label="Login"
                            :append-icon="'mdi-form-textbox'"
                            v-model="form.fp3d_login"
                            :rules="loginRules"
                            type="text"
                            name="fp3d_login"
                            @keyup.enter="submit"
                            tabindex="1"
                            required
                        >
                        </v-text-field>
                        <v-text-field
                            clearable
                            name="password"
                            label="Password"
                            :append-icon="'mdi-lock'"
                            type="password"
                            v-model="form.password"
                            :rules="passwordRules"
                            @keyup.enter="submit"
                            tabindex="2"
                            required
                        >
                        </v-text-field>
                    </v-form>
                    <br>
                    <v-btn
                      color=""
                      @click="submit"
                      :disabled="!valid"
                      large
                      tabindex="3"
                    >Enter</v-btn>
                </v-col>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SignIn',

  data() {
    return {
      valid: false,
      form: {
        fp3d_login: '',
        password: '',
      },
      loginRules: [
        (v) => !!v || 'Login is required',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
      ],
    };
  },

  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
    }),

    validate() {
      this.$refs.form.validate();
    },

    async submit() {
      await this.signIn(this.form);
    },
  },
};
</script>

<style scoped>
    .v-form {
        width: 100%;
    }
</style>
