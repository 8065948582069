import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../pages/Root.vue'),
    name: 'root',
  },
  {
    path: '/analytics',
    component: () => import('../pages/Analytics.vue'),
    name: 'analytics',
  },
  {
    path: '/destination_analytics',
    component: () => import('../pages/DestinationAnalytics.vue'),
    name: 'statistics',
  },
  {
    path: '/userManagement',
    component: () => import('../pages/UserManagement.vue'),
  },
  {
    path: '/customerManagement',
    component: () => import('../pages/CustomerPage.vue'),
  },
  {
    path: '/bigqueryManagement',
    component: () => import('../pages/BigQueryTable.vue'),
  },
  {
    path: '/logVersionManagement',
    component: () => import('../pages/LogVersion.vue'),
  },
  {
    path: '/dashboardManagement',
    component: () => import('../pages/DashboardManagement.vue'),
  },
  {
    path: '/tripbits_videos',
    component: () => import('../pages/VideoAssets.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
