import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import snackbar from './snackbar';
import overlay from './overlay';
import compositeCustomers from './compositeCustomers';
import metrics from './metrics';
import controls from './controls';
import datalists from './datalists';
import menu from './menu';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    snackbar,
    overlay,
    compositeCustomers,
    metrics,
    controls,
    datalists,
    menu,
  },
});
