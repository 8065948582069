export default {
  methods: {
    normalizedDataArray(dataArray) {
      if (!dataArray.length) {
        return [];
      }

      const sum = dataArray.reduce(
        (accum, cur) => (cur.label !== '<All>'
          ? accum + cur.value
          : accum),
        0,
      );

      return dataArray.map(
        (el) => ({
          label: el.label
            ? this.clearQuotes(el.label)
            : 'n/a',
          value: el.value,
          // eslint-disable-next-line no-nested-ternary
          ratio: el.label === '<All>' ? 100 : (sum === 0 ? 0 : ((100 * el.value) / sum).toFixed(2)),
        }),
      );
    },
    clearQuotes(quotedString) {
      return `${quotedString}`.replace(/^"|"$/gm, '');
    },
  },
};
